import React, { useState } from 'react';
import { CardElement, useStripe, useElements, Elements } from '@stripe/react-stripe-js';
import { TextField, Button, Box, Typography, Grid } from '@mui/material';
import { loadStripe } from '@stripe/stripe-js';

// Load Stripe with your publishable key
const stripePromise = loadStripe('pk_live_51OJivDFGjlWbWILL3TAstsh95u5NoeS3qWddHuqeuVuHqFniylercvXCLz6laSXd2uI46rBQaBOQgAgsODWD9pTA00pgvFtx6U');

const DonationFormContent = ({ initialAmount }) => {
    const [amount, setAmount] = useState(initialAmount || '');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const [paymentSuccess, setPaymentSuccess] = useState(false);  // Track payment success
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        if (!stripe || !elements) {
            return;
        }
    
        setIsProcessing(true);
        setError(null);
    
        let clientSecret;
        try {
            // Replace with your actual EC2 instance IP and backend route
            const response = await fetch('https://reinforcement-911.org/create-payment-intent', {  
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ amount: amount * 100 })  // Amount in cents
            });

            // Check for HTTP errors
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            clientSecret = data.clientSecret;
    
            if (!clientSecret) {
                throw new Error('Missing clientSecret from the backend');
            }
        } catch (error) {
            setError(`Payment failed: ${error.message}`);
            setIsProcessing(false);
            return;
        }

        const { error: paymentMethodError, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
            billing_details: {
                name: `${firstName} ${lastName}`,
                email: email,
            },
        });

        if (paymentMethodError) {
            setError(paymentMethodError.message);
            setIsProcessing(false);
            return;
        }

        const { error: confirmError, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
            payment_method: paymentMethod.id,
        });

        if (confirmError) {
            setError(confirmError.message);
            setIsProcessing(false);
            return;
        }

        console.log('Payment successful:', paymentIntent);
        setIsProcessing(false);
        setPaymentSuccess(true);  // Mark payment as successful
    };

    // If paymentSuccess is true, render the Thank You screen
    if (paymentSuccess) {
        return (
            <Box textAlign="center" sx={{ p: 5 }}>
                <Typography variant="h4" color="success.main" gutterBottom>
                    Thank you for your donation!
                </Typography>
                <Typography variant="body1">
                    We truly appreciate your support.
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setPaymentSuccess(false)}  // Optionally reset the form
                    sx={{ mt: 3 }}
                >
                    Make Another Donation
                </Button>
            </Box>
        );
    }

    // Otherwise, render the donation form
    return (
        <Box component="form" onSubmit={handleSubmit} sx={{ '& > :not(style)': { m: 1 } }}>
            <Typography variant="h6" gutterBottom>
                Who's giving today?
            </Typography>
            <Typography variant="body2" gutterBottom color="text.secondary">
                We'll never share this information with anyone.
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        id="firstName"
                        label="First Name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                        fullWidth
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        id="lastName"
                        label="Last Name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        required
                        fullWidth
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="email"
                        label="Email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        fullWidth
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="amount"
                        label="Donation Amount ($)"
                        type="number"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        placeholder="Enter amount"
                        required
                        fullWidth
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Box
                        sx={{
                            border: '1px solid #ccc',
                            borderRadius: '4px',
                            padding: '10px',
                            marginTop: '10px'
                        }}
                    >
                        <CardElement />
                    </Box>
                </Grid>
            </Grid>
            <Typography variant="body2" color="text.secondary">
                This payment is securely processed by Stripe.
            </Typography>
            {error && <Typography color="error">{error}</Typography>}
            <Button
                type="submit"
                disabled={!stripe || isProcessing}
                variant="contained"
                color="primary"
                fullWidth
            >
                {isProcessing ? 'Processing...' : 'Donate'}
            </Button>
        </Box>
    );
};

const DonationForm = ({ amount }) => (
    <Elements stripe={stripePromise}>
        <DonationFormContent initialAmount={amount} />
    </Elements>
);

export default DonationForm;
