// src/components/Header.js
import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Button, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import ContactUsModal from './ContactUsModal';
const Header = () => {
    const [isContactModalOpen, setIsContactModalOpen] = useState(false);

    const handleOpenContactModal = () => {
        setIsContactModalOpen(true);
    };

    const handleCloseContactModal = () => {
        setIsContactModalOpen(false);
    };

    return (
        <AppBar position="static" sx={{ backgroundColor: 'primary.main' }}>
            <Toolbar sx={{ justifyContent: 'space-between' }}>
                <Box display="flex" alignItems="center">
                    <img 
                        src={'assets/reinforcementLogo1.png'} 
                        alt="Reinforcement 911 Logo" 
                        style={{ width: '40px', marginRight: '10px' }}  // Adjust width/margin as needed
                    />
                    <Button color="inherit" component={Link} to="/">
                        <Typography variant="h6" component="div">
                            Reinforcement 911
                        </Typography>
                    </Button>
                </Box>
                <Button color="inherit" onClick={handleOpenContactModal}>
                    Contact Us
                </Button>
            </Toolbar>
            <ContactUsModal open={isContactModalOpen} onClose={handleCloseContactModal} />
        </AppBar>
    );
};

export default Header;
