// src/components/Footer.js
import React from 'react';
import { Box, Typography, Container, Link, IconButton } from '@mui/material';
import { Facebook, Instagram } from '@mui/icons-material'; // Import the icons

const Footer = () => {
    return (
        <Box sx={{ backgroundColor: 'primary.dark', color: 'white', py: 3, mt: 5, textAlign: 'center' }}>
            <Container>
                <Typography>
                    Reinforcement 911 - Minnesota's First Responder Resource Center
                </Typography>
                <Typography variant="body2">
                    © 2024 All rights reserved
                </Typography>
                
                <Box sx={{ mt: 2 }}>
                    
                    <IconButton href="https://www.facebook.com/profile.php?id=61565020721012" 
                        color="inherit" sx={{ mx: 1 }} aria-label="Facebook" 
                        target="_blank" 
                        rel="noopener noreferrer">
                        <Facebook />
                    </IconButton>
                    <IconButton href="https://www.instagram.com/reinforcement911/" 
                        color="inherit" sx={{ mx: 1 }} aria-label="Instagram"
                        target="_blank" 
                        rel="noopener noreferrer">
                        <Instagram />
                    </IconButton>
                </Box>
            </Container>
        </Box>
    );
};

export default Footer;
