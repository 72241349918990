import React from 'react';
import { Modal, Box } from '@mui/material';
import ContactUsForm from './ContactUsForm';

const ContactUsModal = ({ open, onClose }) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="contact-us-modal"
            aria-describedby="modal-for-contact-form"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '80%',
                maxWidth: 600,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: 2,
            }}>
                <ContactUsForm />
            </Box>
        </Modal>
    );
};

export default ContactUsModal;
