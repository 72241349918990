import React, { useState } from 'react';
import { Box, Stepper, Step, StepLabel, StepContent, Button, Paper, Typography, Grid } from '@mui/material';
import DonationForm from './DonationForm';

const DonationSlideshow = () => {

    const [selectedAmount, setSelectedAmount] = React.useState(5);
    const [activeStep, setActiveStep] = useState(0);

    const DonationIntro = () => (
        <Box>
            <Typography variant="body1">
                Help Reinforcement 911's mission by donating today! All donations go directly to making a difference for the first responders in our local communities.
            </Typography>
        </Box>
    );

    const AmountSelection = () => (
        <Box>
            <Typography variant="body1" gutterBottom>
                How much would you like to donate? As a contributor to Reinforcement 911 we make sure your donation goes directly to supporting our cause.
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => {
                            setSelectedAmount(150);
                            handleNext();
                        }}
                    >
                        Donate $150
                    </Button>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => {
                            setSelectedAmount(300);
                            handleNext();
                        }}
                    >
                        Donate $300
                    </Button>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => {
                            setSelectedAmount(550);
                            handleNext();
                        }}
                    >
                        Donate $550
                    </Button>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => {
                            setSelectedAmount(1100);
                            handleNext();
                        }}
                    >
                        Donate $1,100
                    </Button>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => {
                            setSelectedAmount(3500);
                            handleNext();
                        }}
                    >
                        Donate $3,500
                    </Button>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => {
                            setSelectedAmount('custom');
                            handleNext();
                        }}
                    >
                        Custom Amount
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );

    const steps = [
        {
            label: 'Donations',
            component: <DonationIntro />,
        },
        {
            label: 'Choose Amount',
            component: <AmountSelection />,
        },
        {
            label: 'Add Your Information',
            component: <DonationForm amount={selectedAmount} />,
        },
    ];

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleStepClick = (step) => {
        setActiveStep(step);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    return (
        <Box sx={{ maxWidth: 400 }}>
            <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((step, index) => (
                    <Step key={step.label}>
                        <StepLabel
                            optional={
                                index === steps.length - 1 ? (
                                    <Typography variant="caption">Last step</Typography>
                                ) : null
                            }
                            onClick={() => handleStepClick(index)}
                            sx={{ cursor: 'pointer' }}
                        >
                            {step.label}
                        </StepLabel>
                        <StepContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    {step.component}
                                </Grid>
                                <Grid item xs={12}>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                                        {(
                                            <Button
                                                variant="contained"
                                                onClick={handleNext}
                                                sx={{ mt: 1, mr: 1 }}
                                            >
                                                {index === steps.length - 1 ? 'Finish' : 'Continue'}
                                            </Button>
                                        )}
                                    </Box>
                                </Grid>
                            </Grid>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
            {activeStep === steps.length && (
                <Paper square elevation={0} sx={{ p: 3 }}>
                    <Typography>All steps completed - you&apos;re finished</Typography>
                    <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                        Reset
                    </Button>
                </Paper>
            )}
        </Box>
    );
};

export default DonationSlideshow;
