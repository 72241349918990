import React, { useState } from 'react';
import { Paper, Typography, TextField, Button } from '@mui/material';
import emailjs from 'emailjs-com';

const ContactUsForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });

    const [isSent, setIsSent] = useState(false);
    const [error, setError] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Send the email using EmailJS
        emailjs.send(
            'reinforcement',        // Replace with your EmailJS service ID
            'template_u0ik1io',       // Replace with your EmailJS template ID
            formData,
            'hHbJMP_j2aa6jF66C'            // Replace with your EmailJS user ID
        )
        .then((response) => {
            console.log('SUCCESS!', response.status, response.text);
            setIsSent(true);
        }, (err) => {
            console.error('FAILED...', err);
            setError('Failed to send the message. Please try again.');
        });
    };

    return (
        <Paper elevation={3} sx={{ p: 4, backgroundColor: 'background.paper' }}>
            <Typography variant="h4" gutterBottom color="primary.main">
                Contact Us
            </Typography>
            {isSent ? (
                <Typography variant="h6" color="success.main">
                    Your message has been sent successfully!
                </Typography>
            ) : (
                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                    <TextField
                        name="name"
                        label="Name"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={formData.name}
                        onChange={handleChange}
                    />
                    <TextField
                        name="email"
                        label="Email"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={formData.email}
                        onChange={handleChange}
                    />
                    <TextField
                        name="message"
                        label="Message"
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={4}
                        margin="normal"
                        value={formData.message}
                        onChange={handleChange}
                    />
                    {error && (
                        <Typography variant="body2" color="error" gutterBottom>
                            {error}
                        </Typography>
                    )}
                    <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
                        Send Message
                    </Button>
                </form>
            )}
        </Paper>
    );
};

export default ContactUsForm;
