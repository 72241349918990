import React from 'react';
import { Modal, Box, Typography, Button, Link } from '@mui/material';

const InfoPopup = ({ open, handleClose }) => {
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="info-popup-modal"
            aria-describedby="info-popup-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '80%',
                maxWidth: 600,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: 2,
            }}>
                <Typography id="info-popup-modal" variant="h4" component="h2" sx={{ mb: 3, color: 'primary.main', fontWeight: 'bold', textAlign: 'center' }}>
                    Welcome to First Responder Resource Center
                </Typography>
                <Typography id="info-popup-description" variant="body1" sx={{ mb: 3, textAlign: 'center', fontSize: '1.1rem' }}>
                    Join us for the First Responder Fall Fest on <strong>October 24, 4:30 - 7:00 PM</strong>.
                </Typography>
                <Typography variant="body1" sx={{color: 'primary.main', mb: 3, textAlign: 'center', fontSize: '1.1rem' }}>
                    Location: 
                    <Link 
                        href="https://www.google.com/maps?q=4450+Norway+Pine+Pl,+Hermantown,+MN" 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        sx={{ color: 'primary.main', textDecoration: 'underline', fontWeight: 'bold', ml: 1 }}
                    >
                        4450 Norway Pine PL, Hermantown MN
                    </Link>
                </Typography>
                <Box sx={{ mt: 2, mb: 3 }}>
                    <img
                        src="assets/websiteBanner.png"
                        alt="Popup"
                        style={{ width: '100%', display: 'block', borderRadius: '8px' }}
                    />
                </Box>
                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
                    <Button onClick={handleClose} variant="contained" color="secondary">
                        Close
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default InfoPopup;
