// src/pages/NotFound.js
import React from 'react';
import { Typography, Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <Box sx={{ textAlign: 'center', py: 10 }}>
      <Typography variant="h2" gutterBottom>404 - Page Not Found</Typography>
      <Typography variant="h6" gutterBottom>Sorry, the page you are looking for does not exist.</Typography>
      <Button variant="contained" color="primary" component={Link} to="/">Go Home</Button>
    </Box>
  );
}

export default NotFound;
